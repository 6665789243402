<template>
  <v-autocomplete
    class="c-input-xs"
    :class="{'c-input-has-sort': hasSort}"
    v-model="value"
    :items="options"
    :label="label"
    :placeholder="placeholder"
    :prepend-inner-icon="sortPrependIcon"
    @click:prepend-inner="sortClick"
    dense
    outlined
    clearable
    hide-details
    single-line
  ></v-autocomplete>
</template>

<script>
import {httpClient} from "@/libs/http";
import {debounce} from "lodash/function";

export default {
  name: 'SelectDeliveryCompany',
  props: {
    hasSort: {
      type: Boolean,
      default: () => false
    },
    name: {
      type: String,
      default: () => ''
    },
    sortName: {
      type: String,
      default: () => ''
    },
    sorting: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    showNotWarehouse: {
      type: Boolean,
      default: () => false
    },
    /*options: {
      type: Array,
      default: () => []
    },*/
  },
  data: () => ({
    value: null,
    sortValue: null,
    filters: {},
    options: []
  }),
  watch: {
    value() {
      this.onFilter()
    },
    sorting(val) {
      if (val !== this.sortName) {
        this.sortValue = null
      }
    }
  },
  computed: {
    sortPrependIcon() {
      if (!this.hasSort) {
        return ''
      }
      switch (this.sortValue) {
        case 'asc':
          return 'mdi-arrow-up'
        case 'desc':
          return 'mdi-arrow-down'
        default:
          return 'mdi-arrow-up-down'
      }
    }
  },
  methods: {
    onFilter: debounce(function () {
      this.$emit('onFilter', {
        name: this.name,
        value: this.value
      })
    }, 500),
    async getList() {
      const {data} = await httpClient.post('/get-delivery-company', this.filters)
      this.options = data.map(e => ({
        value: e.id,
        text: e.name || ''
      }))
      if (this.showNotWarehouse) {
        this.options.unshift({
          value: 'none',
          text: 'Chưa có dữ liệu'
        })
      }
      if (this.options.length === 1) {
        this.value = this.options[0].value
      }
    },
    sortClick() {
      if (!this.sortValue) {
        this.sortValue = 'asc'
      } else if (this.sortValue === 'asc') {
        this.sortValue = 'desc'
      } else if (this.sortValue === 'desc') {
        this.sortValue = 'asc'
      }
      this.$emit('onSort', {
        sort_by: this.sortName,
        sort_type: this.sortValue
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>
